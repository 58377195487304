<template>
  <div class="">
    <b-row class="m-0">

      <!-- Login-->
      <b-col
        lg="12"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="4"
          md="4"
          lg="4"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Spider Golf
          </b-card-title>
          <b-card-text class="mb-2">
            {{ (`${this.$t('Connect to coach')}`) }}
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="addStudentForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="addStudent"
            >
              <!-- email -->
              <b-form-group
                :label="$t('Coach email')"
                label-for="coach-choose"
              >
                <validation-provider
                  #default="{ }"
                  name="coach-choose"
                >
                  <b-form-select
                    id="coach-choose"
                    v-model="coachChoose"
                    :options="options"
                    name="coach-choose"
                    :state="!validateError[0].status ? false:null"
                  />
                  <small class="text-danger">{{ !validateError[0].status ? validateError[0].message : '' }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="student-email">{{ ($t('Student email')) }}</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="student-email"
                  vid="email"
                  rules="email"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="student-email"
                      v-model="studentEmail"
                      :state="errors.length > 0 || !validateError[1].status ? false:null"
                      class="form-control-merge"
                      name="student-email"
                      :placeholder="$t('Student email')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ !validateError[1].status ? validateError[1].message : errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                {{ ('Connect') }}
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import {
  BRow, BCol, BFormGroup, BFormInput, BInputGroup, BCardText, BCardTitle, BForm, BButton, BFormSelect,
} from 'bootstrap-vue'
import axios from '@axios'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
  },
  data() {
    return {
      coachChoose: null,
      studentEmail: '',

      // validation rules
      required,
      email,
      // studentEmailValite

      options: [
        { value: null, text: `${this.$t('Please select an coach')}` },
        { value: 'ugolf1@spidergolf.fr', text: 'ugolf1@spidergolf.fr' },
        { value: 'ugolf2@spidergolf.fr', text: 'ugolf2@spidergolf.fr' },
        { value: 'ugolf3@spidergolf.fr', text: 'ugolf3@spidergolf.fr' },
        { value: 'ugolf4@spidergolf.fr', text: 'ugolf4@spidergolf.fr' },
      ],
      validateError: [
        { message: `${this.$t('Please select an coach')}`, status: true },
        { message: `${this.$t('Please enter student email')}`, status: true },
      ],
    }
  },
  methods: {
    addStudent() {
      let flag = true
      if (!this.coachChoose) {
        this.validateError[0].status = false
        flag = false
      }
      if (this.studentEmail.length === 0) {
        this.validateError[1].status = false
        flag = false
      }
      if (flag) {
        this.$refs.addStudentForm.validate().then(success => {
          this.validateError[0].status = true
          this.validateError[1].status = true
          if (success) {
            const payload = { coach: this.coachChoose, student: this.studentEmail }

            axios
              .post('/api/add-student/', payload)
              .then(response => {
                if (response.data && response.data.status && response.data.status.error) {
                  let message = `${this.$t('Linking fail')}`
                  switch (response.data.status.errorCode) {
                    case 1:
                      message = `${this.$t('Limit has been reached')}`
                      break
                    case 2:
                      message = `${this.$t('Student has already been linked')}`
                      break
                    case 3:
                      message = `${this.$t('Limit has been reached')}`
                      break
                    default:
                      break
                  }

                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `${this.$t('Linking fail')}`,
                      icon: 'SlashIcon',
                      variant: 'danger',
                      text: message,
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `${this.$t('Linking success')}`,
                      icon: 'UserIcon',
                      variant: 'success',
                      text: 'Linking success',
                    },
                  })
                }
                return response
              })
              .catch()
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
</style>
